#header {
  position: fixed !important;
  /* background-color: #f4006b; */
  background: linear-gradient(90deg, #3aa935, #009ee3);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  min-height: 70px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: fixed;
  z-index: 11;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 11px;
}

.header-inner {
  list-style: none;
  padding: 10px 0;
  margin-bottom: 0;
  position: relative;
}

.header-inner > li:not(.pull-right) {
  float: left;
}

.header-inner > li:not(:last-child) {
  margin-right: -2px;
}

.logo {
  padding: 0px 10px;
  color: #fff;
  display: block;
  font-size: 16px;
  cursor: pointer;
}

.mCS_img_loaded {
  vertical-align: middle;
  border: 0;
  background: url("../../img/sarma-white-logo.svg");
  /* background: url("../../img/fg-logo.png"); */
  width: 53px;
  height: 53px;
  display: inline-block;
}
.pull-right {
  float: right !important;
}
.top-menu {
  list-style: none;
  padding: 0;
}
.top-menu > li {
  display: inline-block;
  margin: 0 1px;
  vertical-align: top;
  min-width: 50px;
}
.top-menu > li:not(#toggle-width):before {
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  z-index: 0;
  border-radius: 2px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.top-menu > li > a {
  color: #fff;
  display: block;
  text-align: center;
  z-index: 1;
  position: relative;
}
.top-menu > li > a > .tm-icon {
  font-size: 24px;
  line-height: 36px;
}
.zmdi-more-vert:before {
  content: "\f19b";
}
.dropdown .dropdown-menu:not([data-animation]).pull-right,
.bootstrap-select .dropdown-menu:not([data-animation]).pull-right,
.btn-group .dropdown-menu:not([data-animation]).pull-right,
.dropdown .dropdown-menu:not([data-animation]).dropdown-menu-right,
.bootstrap-select .dropdown-menu:not([data-animation]).dropdown-menu-right,
.btn-group .dropdown-menu:not([data-animation]).dropdown-menu-right {
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}
.dropdown:not([data-animation]) .dropdown-menu,
.btn-group:not([data-animation]) .dropdown-menu {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  filter: alpha(opacity=0);
  display: block;
}
.dropdown-menu.pull-right {
  right: 0 !important;
  left: auto !important;
  top: auto !important;
}
.dropdown-menu {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.dropdown-menu {
  border-radius: 2px;
}
.dropdown-menu:not([class*="bgm-"]) > li > a {
  color: #4c4c4c;
}
.dropdown-menu > li {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li:hover {
  background-color: #09a0c8ad;
  text-decoration: none;
  cursor: pointer;
}
.dropdown.show .dropdown-menu:not([data-animation]),
.dropup.show .dropdown-menu:not([data-animation]),
.bootstrap-select.show .dropdown-menu:not([data-animation]),
.btn-group.show .dropdown-menu:not([data-animation]) {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1) !important;
  opacity: 1;
  filter: alpha(opacity=100);
}
.show > .dropdown-menu {
  display: block;
}

.top-menu > li:not(#toggle-width):hover:before,
.top-menu > li:not(#toggle-width).show:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
  filter: alpha(opacity=100);
}
.p-r-10 {
  padding-right: 10px !important;
}
.welcometext small {
  color: #fff;
}
small {
  font-size: 11px;
}
.welcometext h2 {
  display: block;
  font-size: 16px;
  color: #fff;
  margin: 0px;
  padding: 0px;
}
.dropdown-menu:not([class*="bgm-"]) > li > a:hover {
  color: #000;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #333333;
  background-color: rgba(0, 0, 0, 0.075);
}

.dropdown-menu.dm-icon > li > i.zmdi {
  color: #4c4c4c;
  line-height: 100%;
  vertical-align: top;
  font-size: 18px;
  width: 28px;
}
.about-icon {
  /* background: url("../../img/about_mistral.PNG") no-repeat 0 0; */
  background-size: contain;
  vertical-align: middle;
  display: inline-block;
  margin-top: -3px;
  margin-right: 15px;
  width: 16px;
}
.logout-icon {
  display: inline-block;
  width: 21px;
  height: 19px;
  background: url("../../img/logout-icon.png") no-repeat 0 0;
  vertical-align: middle;
  margin-right: 7px;
}
