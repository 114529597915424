.modal-container {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 30px auto;
  width: 600px;
  height: 130px;
  text-align: center;
  z-index: 1050;
}
.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 1050;
  background-color: #000;
  transition: opacity 0.15s linear;
  opacity: 0.5;
}
.modal-background {
  overflow: hidden;
  position: fixed;
}

.modal-content {
  border-radius: 3px;
  border: none;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}

.modal-header {
  color: white;
  background: linear-gradient(90deg, #3aa935, #009ee3);
  min-height: 0;
  position: relative;
  border-radius: 0px;
  border: none;
  display: block;
  padding: 17px 26px;
}

.popup-close {
  display: inline-block;
  width: 40px;
  height: 40px;
  /* background: url(../../assets/img/icon-sprite.png) no-repeat -5px -150px; */
  vertical-align: middle;
}

.close:focus,
.popup-close:focus {
  outline: none;
}

.modal-title {
  font-weight: 500;
  font-size: 17px;
  text-align: left;
}

.modal-body p {
  margin: 0 0 10px;
}
