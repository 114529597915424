#main {
  position: relative;
  padding-bottom: 0px;
  padding-top: 80px;
}
.card {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  border: none;
}
.card-body {
  padding: 0px;
}
.bg-hide {
  background: transparent;
  box-shadow: inherit;
}
.card .card-header {
  position: relative;
  border: none;
  background: transparent;
}
body:not(.sw-toggled) .breadcrumb {
  padding: 10px 33px 11px;
}
.breadcrumb.custom-breadcrumb {
  box-shadow: none;
  border-bottom: none;
  margin-bottom: 0px;
}
.breadcrumb {
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0;
}
.breadcrumb {
  padding: 8px 20px;
  margin-bottom: 18px;
  list-style: none;
  background-color: transparent;
  border-radius: 2px;
  display: inherit;
}
.btn:not(.btn-link) {
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
}

.bck-btn {
  margin-right: 0px;
}
.btn {
  text-transform: inherit;
  border: none !important;
}
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.btn-primary,
.btn-primary:hover {
  color: #ffffff;
  background-color: #049fd6;
  border-color: #049fd6;
}
.btn {
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 2px;
}
.addFolder {
  color: #fff !important;
}
.bck-btn i {
  margin-right: 7px;
}
.breadcrumb > .active {
  /* color: #7c7c7c; */
  color: #a9a9a9;
}
.breadcrumb-item > span {
  font-size: 15px;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li > span:not(.last-item, .inactive) {
  color: #a9a9a9;
}
.breadcrumb > li > span:not(.last-item):hover,
.breadcrumb > .active:hover {
  color: #7c7c7c;
  cursor: pointer;
}
.breadcrumb > li + li:before {
  content: "/\00a0";
  padding: 0 5px;
  color: #cccccc;
}

/* .header-button {
  padding-bottom: 5px !important;
} */
/* .doclist-cont {
  padding-bottom: 10px;
} */
.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

/* table css */
.scrollableElement {
  height: 440px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #f7f7f7 !important;
}
/* 
.scrollableElement::-webkit-scrollbar {
  width:6px;
  height: 6px;
}
.scrollableElement::-ms-scrollbar {
  width:6px;
  height: 6px;
}
.scrollableElement::-moz-scrollbar {
  width:6px;
  height: 6px;
}
.scrollableElement::-webkit-scrollbar-thumb{
  background: #519bd5;
  border-radius: 10px;
}
.scrollableElement:hover::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 10px;
}
.scrollableElement::-moz-scrollbar-thumb {
background: #519bd5;
border-radius: 10px;
}
.scrollableElement:hover::-moz-scrollbar-thumb {
background: #519bd5;
border-radius: 10px;
}
.scrollableElement::-ms-scrollbar-thumb {
background: #519bd5;
border-radius: 10px;
}
.scrollableElement:hover:-ms-scrollbar-thumb {
background: #519bd5;
border-radius: 10px;
}

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
} */
.dataTable,
.dataTable th,
.dataTable td {
  box-sizing: content-box;
}
.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: collapse;
  border: 0;
  border-spacing: 0;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 18px;
  color: inherit;
}
.file_name {
  white-space: normal;
  word-wrap: break-word;
  width: 40%;
}
.folder_name {
  white-space: normal;
  word-wrap: break-word;
  width: 40%;
  color: #2196f3;
}
.folder_name:hover {
  cursor: pointer;
}
i.folder {
  color: #e2d400;
}
i.zmdi,
i.fa-trash {
  color: #2196f3;
  cursor: pointer;
}
i.pdf-icon {
  color: #d5598f;
}
i.word {
  color: #4e68c6;
}
.header-button i {
  font-size: 16px;
  margin-right: 10px;
  vertical-align: initial;
}

#myTable th {
  text-transform: inherit !important;
  background: #1d2b50 !important;
  /* background: #f4006b; */
  color: #fff;
  border: 0;
}
th:first-child {
  width: 658px;
}
th:nth-child(2) {
  width: 78px;
}
th:nth-child(3) {
  width: 162px;
}
th:nth-child(4),
th:nth-child(5) {
  width: 2px;
}
th:nth-child(6) {
  width: 3px;
}
.dataTable tbody tr {
  background-color: #ffffff;
}
table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9;
}
.dataTable thead th,
.dataTable thead td {
  padding: 9px 10px;
  border: 0;
}
.column-sorted-odd {
  background-color: #f1f1f1;
}
.column-sorted-even {
  background-color: #fafafa;
}
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6;
}
.dataTable tbody tr {
  background-color: #ffffff;
}
.table td,
.table th {
  border: none;
}
.showRecord {
  float: left;
  display: flex;
  margin-top: 20px;
}
.pagination {
  color: #333;
  float: right;
  text-align: right;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  border: 1px solid #eee;
  padding-top: 0px !important;
  margin-top: 14px;
}
.pagination button {
  border: none;
  background-color: #fff;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}
.pagination button:disabled {
  cursor: default;
  color: #666 !important;
  background: transparent;
}
.pagination button:focus {
  outline: none;
}
.pagination button:not(:disabled):not(.selectedButton):not(.noUse):hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #111)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
}
.selectedButton {
  color: #333;
  background-color: #2196f3 !important;
  border: none;
}
.noUse {
  cursor: text !important;
}

.add-folder-cont {
  border-top: 1px solid #efefef;
  padding: 10px 0;
  min-height: 64px;
}
.add-folder-cont input {
  border-radius: 0px;
}
.add-folder-cont .input-group-addon button {
  color: #fff;
  /* display: block; */
}
.form-control {
  font-size: 14px;
  display: table-cell;
}
.header-button button {
  margin-left: 3px;
}
.btn {
  text-transform: inherit;
}
.form-control:not(.fc-alt) {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
}
.input-group-addon {
  padding: 6px 12px;
}
.card {
  border-radius: 0;
}
#myTable_length {
  width: 320px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0px;
  /* font-weight: bold; */
  color: #333;
  font-weight: 500;
}
#myTable_length label {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 500;
}
.dataTables_wrapper .dataTables_length {
  float: left;
}
#myTable_length select {
  border: 0px !important;
  border-bottom: 1px solid #767676 !important;
  height: 30px;
  padding: 0px 5px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 0 5px;
  /* prev theme :: 
   height: 30px;
  padding: 0px 5px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin: 0 5px; */
}
#myTable_length select:focus-visible {
  border: 0px !important;
  border-bottom: 1px solid #767676 !important;
}

#myTable_filter {
  margin-bottom: 10px;
}
.dataTables_filter {
  float: right;
  text-align: right;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}
.dataTables_wrapper .dataTables_filter input:focus {
  outline: none;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
}

.modal .modal-content {
  box-shadow: 0 5px 20px rgb(0 0 0 / 31%);
  border-radius: 3px;
  border: 0;
  height: auto;
  text-align: left;
}
.modal .modal-header {
  padding: 17px 26px;
}
.modal-header {
  border-bottom: none;
}
.jpopup-close-btn {
  position: absolute;
  right: 0;
  top: 0;
  background: #1d2b50 !important;
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  margin: 0px !important;
  opacity: 1;
  padding: 0;
  line-height: 1;
}
.modal-header .close {
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #1d2b50 !important;
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  margin: 0px !important;
  opacity: 1;
  line-height: 40px;
  text-align: center;
  font-weight: 300;
}
.modal-title {
  margin: 0;
  font-size: 17px;
  /* position: absolute; */
  line-height: 18px;
}
.treeview {
  height: 27rem;
  overflow: hidden;
  overflow-y: scroll;
}

.treeview > ul,
.treeview li {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.doct li {
  background: #dfdfdf;
  line-height: 40px;
  padding: 0 20px;
}
ul.doct.selectedfolder li label,
.selectedfolder li input {
  margin-bottom: 0px;
  margin-top: 0;
}

.treeview input {
  position: absolute;
  opacity: 0;
  width: 25px;
}
.doct label {
  margin-bottom: 0px;
}
.treeview label {
  background-position: 18px 0;
  font-weight: inherit;
}
.treeview label,
.treeview a,
.treeview label::before {
  display: inline-block;
  height: 17px;
  line-height: 16px;
  vertical-align: middle;
}
.treeview label,
.treeview label::before {
  background: url("../../img/icons.png") no-repeat;
}
.treeview label,
.treeview label::before {
  cursor: pointer;
}
.treeview label::before {
  content: "";
  width: 25px;
  margin: 0 5px 0 0;
  vertical-align: middle;
  background-position: 0 -36px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e2d400;
}
.treeview input:checked + label::before {
  background-position: 0 -17px;
}
.treeview ul.doct label::before {
  background-position: 0 -17px;
}
.treeviewdoc.myCustomId label {
  text-indent: 0px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 99%;
  overflow: hidden;
  margin-bottom: 5px;
}
/* .modal-body {
  height: 500px;
  overflow-y: scroll;
} */
.about-logo {
  /* background: url('../../img/fg-logo.svg') no-repeat; */
  background: url("../../img/Mistral_logo.svg") no-repeat;
  width: 126px;
  margin: auto;
  height: 74px;
  padding: 50px 0px;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 26px 10px;
}
.modal {
  overflow: hidden !important;
  /* overflow-y: scroll; */
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}
.treeviewdoc.myCustomId {
  border: 1px solid #d4d4d4;
}
.treeviewdoc.myCustomId li {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .modal-dialog {
    margin: 30px auto;
  }
}
.modal-open {
  overflow: hidden;
  padding-right: 17px;
}
.modal-footer {
  border-top: none;
}
.p-15 {
  padding: 15px;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-t-11 {
  padding-top: 11px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-l-28 {
  margin-left: 28px;
}
.m-l-7 {
  margin-left: 7px !important;
}
.m-r-1 {
  margin-right: 1px !important;
}
.table td,
.table th {
  padding: 6px 10px;
}

.deleteDialog {
  height: auto;
  max-height: 500px;
  width: 400px;
  top: 364.167px;
  left: 209.5px;
  display: block;
  z-index: 101;
  background: #fff;
  border: 1px solid #aaa;
  padding: 0.1em;
  margin: auto;
}

/* .alertDialog {
  height: 165px;
} */

.deleteDialogTitle {
  background: #1d2b50;
  border: none;
  padding: 0.4em 1.1em;
  text-align: left;
  cursor: move;
}

.deleteDialog .ui-dialog-title {
  font-size: 1.1em;
  color: #fff;
  font-weight: bold;
  font-family: Verdana, Arial, sans-serif;
}

.deleteDialog .ui-dialog-content {
  padding: 0.5em 0em;
  /* min-height: 1388px; */
  line-height: 40px;
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
}

.alertDialog .ui-dialog-content {
  padding-top: 1em;
  line-height: 28px;
}

.deleteDialog .ui-dialog-buttonset button {
  background: #2196f3 !important;
  color: #fff !important;
  border: none !important;
  padding: 5px 10px !important;
  border-radius: 2px !important;
  font-size: 13px;
  line-height: 16px;
  margin-right: 10px;
  font-family: Verdana, Arial, sans-serif;
  cursor: pointer;
}

.deleteDialog .ui-dialog-buttonpane {
  border-top: 1px solid #aaa;
  padding: 15px;
  text-align: right;
}
.document_landing {
  padding: 8px 0px 0px;
}
#shareModalPopup-content .modal-header {
  background: linear-gradient(90deg, #3aa935, #009ee3);
}
#shareModalPopup-content .modal-header .close {
  background: transparent !important;
}
#shareModalPopup-content .tree-box {
  padding: 15px 0px;
}
#shareModalPopup-content .zmdi-square-right,
.zmdi-close,
.zmdi-delete {
  color: white !important;
}
.treeviewdoc > ul {
  margin-bottom: auto !important;
}
.FileContent ul {
  list-style: none;
  padding: 8px 48px;
  line-height: 25px;
  font-size: 13px;
  text-align: left;
}
.aboutModal .close {
  opacity: 1;
  background: none;
  position: absolute;
  right: 10px;
  background: transparent !important;
}
