.add-user-section {
  margin-top: 100px !important;
  padding-top: 0px !important;
}

.user-form {
  margin-left: 0px;
  color: #000;
}

.user-form-box {
  margin: 0px;
}
.user-label {
  font-size: 20px;
}

.head-btn {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}
.header-button .zmdi-folder-star {
  color: #fff;
}
.add-user-section ul.head-btn {
  justify-content: flex-end;
  list-style-type: none;
}
.add-user-section ul.head-btn li a {
  text-decoration: none;
  /* color: white ; */
  font-size: 13px;
}

.user-list ul.head-btn li a i {
  color: #fff;
}
/* .user-form .row {
    margin-left: 0px;
} */

.user-form-box .labels {
  margin: 10px 0px;
}

.user-form-box .form-control {
  padding: 5px;
}

.form-sumbmit-area {
  margin: 30px 0px;
}

.form-sumbmit-btn {
  background: #2196f3;
  color: #fff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
  border: none;
  padding: 5px 15px;
  border-radius: 2px;
}
.back-icon {
  display: inline-block;
  width: 28px;
  height: 24px;
  background: url(../../img/icon_sprite.png) no-repeat -14px -324px;
  vertical-align: middle;
  margin-right: 10px;
}

.mandatory {
  color: #f6675d;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #f6675d;
}
.card-section {
  margin-top: 100px;
}

.user-list table.dataTable tbody tr {
  background-color: #ffffff;
}
.user-list .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}
.fa.fa-pencil-square-o {
  /* color: #2196f3; */
}
.disabled {
  cursor: not-allowed;
}

.disabled-pagination {
  cursor: not-allowed;
  pointer-events: none;
}

.user-list .footer-pagging-cont {
  position: relative;
  margin-top: 25px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}
.user-list .paging-links {
  background: #049fd6;
  border-radius: 5px;
  margin: 0px auto;
  width: 335px;
  padding: 2px 7px 5px;
}
.user-list .paging-links a {
  margin: 0;
  padding: 0 5px;
  display: inline-block;
  height: 23px;
  vertical-align: middle;
}
.user-list .paging-links a:first-child {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
.user-list .firstI.disabled-link {
  background: rgba(0, 0, 0, 0) url(../../img/pagination-btns.png) no-repeat
    scroll 0 -192px;
  display: inline-block;
  width: 25px;
  height: 25px;
}
.user-list .pagerPrev.disabled-link {
  background: rgba(0, 0, 0, 0) url(../../img/pagination-btns.png) no-repeat
    scroll 0 -128px;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.user-list input.display-page {
  margin: 0px 8px -3px;
  text-align: center;
  width: 40px;
  border: none;
  vertical-align: middle;
  height: 16px;
}
.user-list .pagerNext.disabled-link {
  background: rgba(0, 0, 0, 0) url(../../img/pagination-btns.png) no-repeat
    scroll 0 -160px;
  display: inline-block;
  width: 25px;
  height: 25px;
}
.user-list .lastI.disabled-link {
  background: rgba(0, 0, 0, 0) url(../../img/pagination-btns.png) no-repeat
    scroll 0 -224px;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.user-list .CustPageLabelClass {
  color: #fff;
  /* padding: 5px 0px 0; */
  vertical-align: middle;
}
.user-list .header-button i {
  font-size: 16px;
  margin-right: 7px;
  vertical-align: bottom;
  color: #049fd6;
  cursor: pointer;
}
.label_span {
  font-size: 15px;
  padding: 8px 0px 0px;
}
.alert-dismissible {
    left: calc(50% - 100px);
    padding: 8px 50px;
    position: absolute;
    z-index: 999;
    
}
  
/* #successMsgDiv {
  margin: 6px;
  right: 0px;
  position: absolute;
  z-index: 999;
}
#successMsgDiv i {
  padding: 3px 8px;
} */
.save_btn {
  padding: 8px 20px;
  width: max-content;
}
