body {
  font-family: roboto;
  font-weight: 400;
  position: relative;
  font-size: 13px;
  line-height: 1.42857143;
  color: #5e5e5e;
  background-color: #edecec;
}
.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-header:after, .modal-footer:after {
  clear: both;
}
.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-header:before, .modal-header:after, .modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}

/*
 * Roboto Light
 */

 @font-face {
  font-family: roboto;
  src: url('./fonts/roboto-light-webfont.eot');
  src: url('./fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'), 
  url('./fonts/roboto-light-webfont.woff') format('woff'), 
  url('./fonts/roboto-light-webfont.ttf') format('truetype'), 
  url('./fonts/roboto-light-webfont.svg#icon') format('svg');
  font-weight: 300;
  font-style: normal;
}

/*
 * Roboto Regular
 */

@font-face {
  font-family: roboto;
  src: url('./fonts/roboto-regular-webfont.eot');
  src: url('./fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'), 
  url('./fonts/roboto-regular-webfont.woff') format('woff'), 
  url('./fonts/roboto-regular-webfont.ttf') format('truetype'), 
  url('./fonts/roboto-regular-webfont.svg#icon') format('svg');
  font-weight: 400;
  font-style: normal;
}

/*
 * Roboto Medium
 */

@font-face {
  font-family: roboto;
  src: url('./fonts/roboto-medium-webfont.eot');
  src: url('./fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'), 
  url('./fonts/roboto-medium-webfont.woff') format('woff'), 
  url('./fonts/roboto-medium-webfont.ttf') format('truetype'), 
  url('./fonts/roboto-medium-webfont.svg#icon') format('svg');
  font-weight: 500;
  font-style: normal;
}

/*
 * Roboto Bold
 */

@font-face {
  font-family: roboto;
  src: url('./fonts/roboto-bold-webfont.eot');
  src: url('./fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'), 
  url('./fonts/roboto-bold-webfont.woff') format('woff'), 
  url('./fonts/roboto-bold-webfont.ttf') format('truetype'), 
  url('./fonts/roboto-bold-webfont.svg#icon') format('svg');
  font-weight: 700;
  font-style: normal;
}
/* 
@font-face {
  font-family: roboto;
  src: local('roboto'), url("./fonts/roboto-regular-webfont.woff") format('woff');
} */

.loader {
  position: relative;
}

.loader-img {
  height: 40px;
  width: 40px;
  background: url(./img/light-loader.gif);
  position: absolute;
  left: 50%;
  top: 250px;
  transform: translateX(-50%);
}
.not-page {
  margin-top: 100px;
  font-size: 30px;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1d2b50; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #172650; 
}